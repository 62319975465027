import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import './style.css'; // Import your custom styles

import ItemPdf from './Item.pdf';

const App = () => {
  const [pdfUrl] = useState(ItemPdf);
  return (
    <div className="background-container">
      <div className="gradient-background">
        {/* <h1 className="white-text">Machaxi Shop</h1> */}
        <div className="pdf-container">
           <Worker workerUrl={`https://cdn.jsdelivr.net/npm/pdfjs-dist/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfUrl} defaultScale={0.48} />
           </Worker>
        </div>
      </div>
    </div>
  );
};

export default App;
